<template>
    <div class="container row mx-auto mb-10 py-10">
      <div class="col-md-12"><h2 class="text-center text-blue" style="font-size: 40px; margin: 30px 0px;">MANAGEMENT PROFILES</h2></div>
<!--      <div class="col-md-4 col-12 text-center p-4 pt-8" style="min-height: 230px; max-height: 420px; background: rgba(255, 255, 255, 0.25);">
          <img v-lazy="picChairman" alt="Oaishe Khan, Chairman" width="auto" height="75%">
          <h3 class="text-white pt-2">Oaishe Khan</h3>
          <h5 class="text-black-50 pt-0">BA Hons (Accounting & <br/>Finance) UK</h5>
          <h4 class="text&#45;&#45;white">Chairman</h4>
      </div>-->
      <div class="col-md-6 col-12 text-center p-4 pt-8" style="min-height: 230px; max-height: 420px; background: rgba(255, 255, 255, 0.25);">
        <img v-lazy="picMD" alt="Md. Al-Amin Hossain, Managing Director" width="auto" height="75%">
        <h3 class="text-white pt-2">Md. Al-Amin Hossain</h3>
        <h5 class="text-black-50 pt-0">BSc Hons (Business & Management) UK</h5>
        <h4>Managing Director</h4>
      </div>
<!--      <div class="col-md-4 col-12 text-center p-4 pt-8" style="min-height: 230px; max-height: 420px; background: rgba(255, 255, 255, 0.25);">
        <img v-lazy="picCOO" alt="Md. Tarek Arafat, Director, COO" width="60%" height="auto">
        <h3 class="text-white pt-2">Md. Tarek Arafat</h3>
        <h5 class="text-black-50 pt-0">BSc Hons (Computer Science & <br/>Engineering)</h5>
        <h4>Director, COO</h4>
      </div>-->
      <div class="col-md-6 col-12 text-center p-4 pt-8" style="min-height: 230px; max-height: 420px; background: rgba(255, 255, 255, 0.25);">
        <img v-lazy="picCTO" alt="Md. Khalid Musa Sagar, Director, CTO" width="auto" height="75%">
        <h3 class="text-white pt-2">Md. Khalid Musa Sagar</h3>
        <h5 class="text-black-50 pt-0">MSc (Computer Science & Engineering)</h5>
        <h4>Director, CTO</h4>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'Management',
    computed:{
      picChairman: function () {
        return "https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/team/Oaishe+Khan.png";
      },
      picMD: function () {
        return "https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/team/Md.+Al-Amin+Hossain.png";
      },
      picCOO: function () {
        return "https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/team/Md.+Tarek+Arafat.jpg";
      },
      picCTO: function () {
        return "https://chokrojan-bucket.s3.ap-southeast-1.amazonaws.com/company/team/Md.+Khalid+Musa+Sagar.png";
      }
    }
}
</script>

<style scoped>

</style>
